import React from 'react';
import shallow from 'zustand/shallow';
import {changeBannerBackground} from '../helpers/utils';
import {usePhotoStore} from '../store';

const GetAboutBanner = ({text}) => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>{text}</h1>
      </header>
    </div>
  </section>
);

const BannerProjects = ({different, text}) => {
  const {userInfo, currentProject} = usePhotoStore(
    (state) => ({userInfo: state.userInfo, currentProject: state.currentProject}),
    shallow
  );
  let projectDescription = 'Projects';

  if (userInfo.bannerImage && userInfo.bannerImage.fields.file) {
    changeBannerBackground(userInfo.bannerImage.fields.file.url);
  }

  if (different) {
    return <GetAboutBanner text={text} />;
  }

  const projectAssets = currentProject && currentProject.assets;

  projectDescription = currentProject && currentProject.projectDescription;

  const firstAsset = projectAssets && projectAssets[0];
  const longProjectDescription = firstAsset && firstAsset.fields.description;

  if (userInfo.bannerImage && userInfo.bannerImage.fields.file) {
    changeBannerBackground(userInfo.bannerImage.fields.file.url);
  }

  return currentProject ? (
    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>{projectDescription}</h1>
        </header>
        <div className="content">
          <span>{longProjectDescription || projectDescription}</span>
        </div>
      </div>
    </section>
  ) : (
    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>Project unavailable!</h1>
        </header>
      </div>
    </section>
  );
};

export default BannerProjects;
