import React from 'react';
import {Link} from 'gatsby';

const Header = ({onToggleMenu}) => (
  <header id="header" className="alt">
    <Link className="logo" to="/">
      <strong>Photography</strong> <span>by Kuvatlanka</span>
    </Link>
    <nav>
      <a
        className="menu-link"
        tabIndex={0}
        role="button"
        onKeyDown={onToggleMenu}
        onClick={onToggleMenu}>
        Menu
      </a>
    </nav>
  </header>
);

export default Header;
