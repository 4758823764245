import React from 'react';
import shallow from 'zustand/shallow';
import {usePhotoStore} from '../store';

const Footer = () => {
  const {userInfo} = usePhotoStore(
    (state) => ({
      userInfo: state.userInfo,
    }),
    shallow
  );

  const userNumber = `+${userInfo.phoneDdi || '55'}${userInfo.phoneDdd || '21'}${
    userInfo.phoneNumber || '985151995'
  }`;
  const whatsAppMessage = 'Olá, adorei seu trabalho, podemos conversar?';

  return (
    userInfo && (
      <footer id="footer">
        <div className="inner">
          <ul className="icons">
            <li>
              <a href={userInfo.socialFacebook} className="icon alt fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href={userInfo.socialInstagram} className="icon alt fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href={`https://api.whatsapp.com/send?phone=${userNumber}&text=${whatsAppMessage}`}
                className="icon alt fa-whatsapp">
                <span className="label">WhatsApp</span>
              </a>
            </li>
          </ul>
          <ul className="copyright">
            <li>Giselle Parno</li>
            <li>
              Design: <a href="https://olavoparno.vercel.app">Olavo Parno</a>
            </li>
          </ul>
        </div>
      </footer>
    )
  );
};

export default Footer;
