import displayableProjects from '../data/displayableProjects.json';
import projectAssets from '../data/projectAssets.json';
import portfolioInfo from '../data/portfolioInfo.json';

export const getPage = () => {
  return typeof global.window !== 'undefined' ? global.window.location.pathname : '';
};

export const getUser = () => {
  return portfolioInfo;
};

export const getProjects = () => {
  const {projects} = displayableProjects;

  return projects.map((item) => {
    const projectName = item.split('.').shift();
    const [projectTitle] = item.split('.').slice(1, 2);
    const projectDescription = item.split('.').pop();
    const assets = projectAssets[`${[projectName]}Photos`];
    let firstProjectPhoto = '../assets/images/pic01.jpg';

    if (assets) {
      const [, firstAsset] = assets;
      firstProjectPhoto =
        firstAsset && firstAsset.fields && firstAsset.fields.file && firstAsset.fields.file.url;
    }

    return {
      assets,
      projectName,
      projectTitle,
      projectDescription,
      firstProjectPhoto,
    };
  });
};

export const changeBannerBackground = (url) => {
  if (typeof global.document !== 'undefined') {
    const banner = global.document.getElementById('banner');

    if (banner) banner.style.backgroundImage = `url("${url}")`;
  }
};
