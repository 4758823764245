import React, {useEffect} from 'react';
import shallow from 'zustand/shallow';
import {usePhotoStore} from '../store';

const sendMessage = (userNumber) => {
  if (typeof global.document !== 'undefined') {
    const objTitle = global.document.getElementById('name');
    const objEmail = global.document.getElementById('email');
    const objMessage = global.document.getElementById('message');

    let strMessage = `Name: ${objTitle.value}%20E-mail: ${objEmail.value}%20Mensagem: ${objMessage.value}`;

    if (!objMessage.value) strMessage = 'Olá, adorei seu trabalho, podemos conversar?';

    global.window.location = `https://api.whatsapp.com/send?phone=${userNumber}&text=${strMessage}`;
  }
};

const Contact = () => {
  const {userInfo, getUserInfo} = usePhotoStore(
    (state) => ({
      userInfo: state.userInfo,
      getUserInfo: state.getUserInfo,
    }),
    shallow
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getUserInfo();
    }

    return () => {
      mounted = false;
    };
  }, [getUserInfo]);

  const strEmail = userInfo.userEmail;
  const objPhone = {
    phoneNumber: userInfo.phoneNumber,
    phoneDdd: userInfo.phoneDdd,
    phoneDdi: userInfo.phoneDdi,
  };

  const objAddress = {
    addressCity: userInfo.addressCity,
    addressCountry: userInfo.addressCountry,
    addressNumber: userInfo.addressNumber,
    addressState: userInfo.addressState,
    addressStreet: userInfo.addressStreet,
    addressZip: userInfo.addressZip,
  };

  const userNumber = `+${userInfo.phoneDdi}${userInfo.phoneDdd}${userInfo.phoneNumber}`;

  return (
    <section id="contact">
      <div className="inner">
        <section>
          <form name="form 1" method="POST" netlify="true">
            <input type="hidden" name="form-name" value="form 1" />
            <div className="field half first">
              <label htmlFor="name">
                <input type="text" name="name" id="name" />
                Name
              </label>
            </div>
            <div className="field half">
              <label htmlFor="email">
                <input type="text" name="email" id="email" />
                Email
              </label>
            </div>
            <div className="field message">
              <label htmlFor="message">
                <textarea name="message" id="message" rows="6" />
                Message
              </label>
            </div>
            <ul className="actions contact">
              <li>
                <input
                  type="submit"
                  className="button special icon fa-envelope"
                  value="Send E-Mail"
                />
              </li>
              <li>
                <input
                  type="button"
                  onClick={() => sendMessage(userNumber)}
                  className="button special icon fa-whatsapp"
                  value="Send with WhatsApp"
                />
              </li>
              <li>
                <input type="reset" value="Clear" />
              </li>
            </ul>
          </form>
        </section>
        <section className="split">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-envelope" />
              <h3>Email</h3>
              <span>{strEmail}</span>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-phone" />
              <h3>Phone</h3>
              <span>{`(+${objPhone.phoneDdi}) ${objPhone.phoneDdd} ${objPhone.phoneNumber}`}</span>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-home" />
              <h3>Address</h3>
              <span>
                {`${objAddress.addressStreet}, ${objAddress.addressNumber}`}
                <br />
                {`${objAddress.addressCity}, ${objAddress.addressState} ${objAddress.addressZip}`}
                <br />
                {objAddress.addressCountry}
              </span>
            </div>
          </section>
        </section>
      </div>
    </section>
  );
};

export default Contact;
