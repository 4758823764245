import React, {useState, useEffect} from 'react';
import shallow from 'zustand/shallow';
import Helmet from 'react-helmet';
import '../assets/scss/main.scss';

import Header from './Header';
import Menu from './Menu';
import Contact from './Contact';
import Footer from './Footer';
import Banner from './Banner';
import BannerProjects from './BannerProjects';
import {getPage} from '../helpers/utils';

import {usePhotoStore} from '../store';

const Layout = ({children}) => {
  const {getUserInfo, userInfo} = usePhotoStore(
    (state) => ({
      getUserInfo: state.getUserInfo,
      userInfo: state.userInfo,
    }),
    shallow
  );
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isLoading, setIsLoading] = useState('is-loading');

  useEffect(() => {
    let mounted = true;

    if (mounted && getUserInfo) {
      getUserInfo();
      setIsLoading('');
    }

    return () => {
      mounted = false;
    };
  }, [getUserInfo]);

  const handleToggleMenu = () => {
    setMenuVisible(() => !isMenuVisible);
  };

  const BannerHandler = () => {
    const page = getPage();

    if (page === '/') {
      return <Banner />;
    }

    if (page.indexOf('about') > 0) {
      return <BannerProjects different text="About me" />;
    }

    if (page.indexOf('projects') > 0) {
      return <BannerProjects different text="Projects" />;
    }

    return <BannerProjects />;
  };

  return (
    userInfo && (
      <div className={`body ${isLoading} ${isMenuVisible ? 'is-menu-visible' : ''}`}>
        <Helmet
          title="Kuvatlanka Photography"
          meta={[
            {
              name: 'description',
              content: 'Kuvatlanka Photography',
            },
          ]}
          link={[
            {
              rel: 'shortcut icon',
              href: './favicon-32x32.png',
              type: 'image/x-icon',
            },
            {
              rel: 'icon',
              href: './favicon-32x32.png',
              type: 'image/x-icon',
            },
            {
              href: 'https://fonts.googleapis.com/css?family=Ubuntu&display=swap',
              rel: 'stylesheet',
            },
            {
              href:
                'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css',
              rel: 'stylesheet',
            },
            {
              href: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,600,600i',
              rel: 'stylesheet',
            },
          ]}
        />
        <style dangerouslySetInnerHTML={{__html: ''}} />
        <div id="wrapper">
          <BannerHandler />
          <Header onToggleMenu={handleToggleMenu} />
          {children}
          <Contact />
          <Footer />
        </div>
        <Menu onToggleMenu={handleToggleMenu} />
      </div>
    )
  );
};

export default Layout;
