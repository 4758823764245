import React from 'react';
import shallow from 'zustand/shallow';
import {changeBannerBackground} from '../helpers/utils';
import {usePhotoStore} from '../store';

const Banner = () => {
  const {userInfo} = usePhotoStore((state) => ({userInfo: state.userInfo}), shallow);
  const strIntroduction = userInfo.userName;
  const {presentationText} = userInfo;
  const [contentDocument] = presentationText.content;
  const [contentParagraph] = contentDocument.content;

  if (userInfo.bannerImage && userInfo.bannerImage.fields.file) {
    changeBannerBackground(userInfo.bannerImage.fields.file.url);
  }

  return (
    <section id="banner" className="major">
      <div className="inner">
        <header className="major">
          <h1>{strIntroduction}</h1>
        </header>
        <div className="content">
          <p>{contentParagraph.value}</p>
          <ul className="actions">
            <li>
              <a href="#one" className="button next scrolly">
                Get Started
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Banner;
