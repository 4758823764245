import create from 'zustand';
import {devtools} from 'zustand/middleware';
import {getProjects, getUser} from '../helpers/utils';

const photoStore = (set, get) => ({
  projectsInfo: null,
  currentProject: null,
  userInfo: null,
  setCurrentProject: (projectItem) => {
    set((state) => ({...state, currentProject: projectItem}), 'setCurrentProject');
  },
  getProjectsInfo: () => {
    const projectsInfo = getProjects();
    set((state) => ({...state, projectsInfo}), 'getProjectsInfo');

    return projectsInfo;
  },
  getCurrentProject: () => {
    return get().currentProject;
  },
  getUserInfo: () => {
    const userInfo = getUser();
    set((state) => ({...state, userInfo}), 'getUserInfo');

    return userInfo;
  },
});

export const usePhotoStore = create(devtools(photoStore, 'Kuvatlanka'));
