import React from 'react';
import {Link} from 'gatsby';

const Menu = ({onToggleMenu}) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link to="/">
            <span tabIndex={0} role="button" onKeyDown={onToggleMenu} onClick={onToggleMenu}>
              Home
            </span>
          </Link>
        </li>
        <li>
          <Link to="/projects">
            <span tabIndex={0} role="button" onKeyDown={onToggleMenu} onClick={onToggleMenu}>
              Projects
            </span>
          </Link>
        </li>
        <li>
          <Link to="/about">
            <span tabIndex={0} role="button" onKeyDown={onToggleMenu} onClick={onToggleMenu}>
              About
            </span>
          </Link>
        </li>
        <li>
          <Link to="/#contact">
            <span tabIndex={0} role="button" onKeyDown={onToggleMenu} onClick={onToggleMenu}>
              Contact
            </span>
          </Link>
        </li>
      </ul>
    </div>
    <a className="close" tabIndex={0} role="button" onKeyDown={onToggleMenu} onClick={onToggleMenu}>
      Close
    </a>
  </nav>
);

export default Menu;
